import { format } from 'date-fns';

export const formatDate = (date: string, dateFormat = 'MM/dd/yyyy') => format(new Date(date), dateFormat);
export const formatDateDetailed = (date: string) => format(new Date(date), 'MM/dd/yyyy - hh:mm');
export const removeTime = (date = new Date()) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};
export const formatDateFull = (date: string) => format(new Date(date), 'MMM d, yyyy HH:mm');

export const isDateWithin = (startDateStr: string, endDateStr: string) => {
  if (!startDateStr || !endDateStr) return false;

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);

  return startDate <= today && today <= endDate;
};
