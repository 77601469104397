import React from 'react';

import { FormControlLabel } from '@mui/material';

import { Switch } from 'components/Switch/Switch';

import styles from './SwitchInput.module.scss';

type Props = {
  onChange: () => void;
  checked: boolean;
  label: string;
  description?: string;
};

export default function SwitchInput({ onChange, checked, label, description }: Props) {
  return (
    <FormControlLabel
      control={<Switch onChange={onChange} checked={checked} />}
      sx={
        description
          ? {
              alignItems: 'flex-start',
            }
          : undefined
      }
      label={
        <>
          <div className={styles.LabelText}>{label}</div>
          {description && <div className={styles.LabelDescriptionText}>{description}</div>}
        </>
      }
    />
  );
}
