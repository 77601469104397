import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Radio, RadioGroup } from 'rsuite';

import NewSearchBar from 'components/NewSearchBar/NewSearchBar';
import NothingFoundText from 'components/NothingFoundText/NothingFoundText';
import Scrollable from 'components/Scrollable/Scrollable';

import { useAppSelector } from 'hooks/redux';

import { useGetAssessmentsQuery } from 'store/api/assessmentApi/assessmentApi';
import { useGetGroupByIdQuery } from 'store/api/groupsApi/groupsApi';

import ROUTES from 'router/routes';

import { formatDate } from 'helpers/date';
import { createDiagnosticAssignmentModal } from 'helpers/validations';

import { IAssessment, TagType, Unit } from 'types/assessmentTypes';
import { ModalSize } from 'types/modalTypes';

import TextInput from '../../Inputs/Text/Text';
import Loader from '../../Loader/Loader';
import Tags from '../../Tags/Tags';
import ModalWrapper from '../ModalWrapper';

import styles from './CreateDiagnosticAssignmentModal.module.scss';

type Props = {
  open: boolean;
  handleClose: () => void;
  selectedId?: string;
};

type FormValues = {
  diagnosticAssignmentName: string;
  selectedDiagnosticId: string;
};

const CreateDiagnosticAssignmentModal = ({ open, handleClose, selectedId, ...props }: Props) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const selectedGroupId = useAppSelector((state) => state.navigation.selectedGroupId);
  const navigate = useNavigate();

  const { data, isLoading } = useGetAssessmentsQuery(undefined);
  const { data: groupData, isLoading: isLoadingGroupData } = useGetGroupByIdQuery(selectedGroupId, {
    skip: !selectedGroupId || !open,
  });

  const { register, handleSubmit, formState, watch, getFieldState, reset, control } = useForm<FormValues>({
    resolver: yupResolver(createDiagnosticAssignmentModal(t)),
    mode: 'all',
    defaultValues: {
      diagnosticAssignmentName: '',
      selectedDiagnosticId: selectedId || '',
    },
  });

  useEffect(() => {
    if (groupData) {
      reset({
        diagnosticAssignmentName: `${groupData.name}: ${formatDate(new Date().toISOString(), 'yyyy/MM/dd')}`,
      });
    }
  }, [groupData, reset]);

  const assessments = (data as Unit[])?.reduce((res, item) => {
    res.push(...item.assessments);
    return res;
  }, [] as IAssessment[]);

  const filteredAssessments = assessments?.length
    ? [...new Map(assessments.map((item) => [item.id, item])).values()].filter((item) =>
        selectedId ? item.id === selectedId : item.title.trim().toLowerCase().includes(search.trim().toLowerCase()),
      )
    : [];

  const selectedDiagnosticId = watch('selectedDiagnosticId');
  const selectedDiagnostic = assessments?.find((item) => item.id === selectedDiagnosticId);
  const tags = selectedDiagnostic
    ? [
        {
          id: selectedDiagnostic?.id,
          title: selectedDiagnostic?.title,
          type: TagType.Organization,
        },
      ]
    : [];

  const addAssignees = ({ diagnosticAssignmentName }: FormValues) => {
    if (selectedDiagnostic) {
      navigate(ROUTES.DIAGNOSTIC_ASSIGNMENT_CREATE, {
        state: {
          diagnosticAssignmentName,
          selectedDiagnostic: { id: selectedDiagnostic.id, title: selectedDiagnostic.title },
        },
      });
    }
  };

  const onClose = () => {
    setSearch('');
    reset();
    handleClose();
  };

  return (
    <ModalWrapper
      {...props}
      title={t('assessment.new_assignment')}
      open={open}
      close={onClose}
      actionTitle={t('general.create')}
      cancelTitle={t('general.cancel')}
      actionDisabled={!formState.isValid}
      action={handleSubmit(addAssignees)}
      modalSpacing={ModalSize.NoSpacing}
    >
      <div className={styles.Description}>
        {selectedId
          ? t('assessment.assignment_selected_modal_description')
          : t('assessment.assignment_modal_description')}
      </div>

      <TextInput
        disabled={isLoadingGroupData}
        register={register('diagnosticAssignmentName')}
        label={t('assessment.assignment_name')}
        placeholder={t('assessment.assignment_name')}
        error={getFieldState('diagnosticAssignmentName', formState)}
        error_message={t(formState?.errors.diagnosticAssignmentName?.message || '')}
      />

      <div className={styles.Divider} />

      <span className={styles.DiagnosticName}>{t('assessment.diagnostic_name')}</span>

      {tags.length ? (
        <div className={styles.TagsContainer}>
          <Tags tags={tags} handleDelete={selectedId ? undefined : () => reset({ selectedDiagnosticId: '' })} />
        </div>
      ) : null}

      {selectedId ? null : (
        <div className={styles.SearchBar}>
          <NewSearchBar search={search} onSearch={setSearch} />
        </div>
      )}

      {isLoading ? (
        <div className={styles.LoaderContainer}>
          <Loader flexCenter size="md" />
        </div>
      ) : selectedId ? null : (
        <Scrollable>
          <Controller
            control={control}
            name="selectedDiagnosticId"
            render={({ field: { onChange, value } }) => (
              <RadioGroup value={value} onChange={onChange}>
                {filteredAssessments.length > 0 ? (
                  filteredAssessments?.map((item) => (
                    <Radio key={item.id} value={item.id} className={styles.Radio}>
                      {item.title}
                    </Radio>
                  ))
                ) : (
                  <NothingFoundText style={{ minHeight: '40px' }} text={t('assessment.nothing_found')} />
                )}
              </RadioGroup>
            )}
          />
        </Scrollable>
      )}
    </ModalWrapper>
  );
};

export default CreateDiagnosticAssignmentModal;
