import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import startCase from 'lodash/startCase';

import CardInfo from 'components/CardInfo/CardInfo';
import Loader from 'components/Loader/Loader';

import { useAppSelector } from 'hooks/redux';

import { useGetCompletionByIdQuery, useGetLastCompletionGroupQuery } from 'store/api/assessmentApi/assessmentApi';

import { formatDate, formatDateDetailed } from 'helpers/date';

import { DashboardSearchParams, DashboardType } from 'types/dashboardTypes';
import { CardColor } from 'types/enums';

import styles from './HeaderInfo.module.scss';

type Props = {
  dashboardType: DashboardType;
  isComparisonMode: boolean;
};

const HeaderInfo = ({ dashboardType, isComparisonMode }: Props) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const selectedGroupId = useAppSelector((state) => state.navigation.selectedGroupId);

  const completionId1 = searchParams.get(DashboardSearchParams.COMPLETION_1) || '';
  const groupId1 = searchParams.get(DashboardSearchParams.GROUP_1) || '';
  const dateFrom = searchParams.get(DashboardSearchParams.DATE_FROM) || '';
  const dateTo = searchParams.get(DashboardSearchParams.DATE_TO) || '';
  const isGroupDashboard = dashboardType === DashboardType.GROUP;
  const isDateRange = Boolean(dateFrom && dateTo && isComparisonMode);
  const dateRangeText = isDateRange && [dateFrom, dateTo].map((date) => formatDate(date)).join(' - ');

  const {
    currentData: completion,
    isLoading: isLoadingCompletionLeft,
    isFetching: isFetchingCompletionLeft,
  } = useGetCompletionByIdQuery(
    { id: completionId1, groupId: selectedGroupId },
    { skip: !completionId1 || isDateRange },
  );

  const {
    currentData: lastCompletionGroup,
    isLoading: isLoadingLastCompletionGroup,
    isFetching: isFetchingLastCompletionGroup,
  } = useGetLastCompletionGroupQuery(
    { groupId: groupId1 || '' },
    { skip: !groupId1 || !isGroupDashboard || isDateRange },
  );

  const dateText = isGroupDashboard
    ? lastCompletionGroup?.date_display
    : completion && formatDateDetailed(completion.attempt_date);

  const isLoadingCompletion = isLoadingCompletionLeft || isFetchingCompletionLeft;
  const isLoadingCompletionGroup = isLoadingLastCompletionGroup || isFetchingLastCompletionGroup;

  if (isGroupDashboard ? isLoadingCompletionGroup : isLoadingCompletion) {
    return (
      <Loader
        style={{
          height: '33.33px',
        }}
        flexCenter
      />
    );
  }

  if (!isDateRange && !dateText) {
    return <div style={{ height: '33.3px' }} />;
  }

  return (
    <div className={styles.Container}>
      <CardInfo color={CardColor.Blue}>
        <span className={styles.LastAssessmentDateText}>{`${startCase(t('dashboard.last_diagnostic_date'))}: `}</span>
        <span>{isDateRange ? dateRangeText : dateText}</span>
      </CardInfo>
    </div>
  );
};

export default HeaderInfo;
