import React from 'react';

import SwitchMUI from '@mui/material/Switch';

type Props = {
  checked: boolean;
  onChange: () => void;
};

export const Switch = ({ checked, onChange }: Props) => {
  return <SwitchMUI sx={SwitchSx} checked={checked} onChange={onChange} />;
};

const SwitchSx = {
  height: 'auto',

  ':has(input:focus-visible)': {
    '.MuiSwitch-track': {
      outline: '-webkit-focus-ring-color auto 1px',
    },
  },

  '.MuiSwitch-thumb': {
    width: '16px',
    height: '16px',
    backgroundColor: 'var(--white)',
    boxShadow: 'none',
  },

  '.MuiSwitch-track': {
    width: '36px',
    height: '20px',
    borderRadius: '1000px',
    backgroundColor: '#A3D6DA',
    opacity: 1,
  },

  '& .MuiSwitch-switchBase': {
    transform: 'translateX(6px) translateY(5px)',
    transitionDuration: '300ms',

    '&.Mui-checked': {
      transform: 'translateX(18px) translateY(5px)',
      color: 'var(--white)',

      '.MuiSwitch-thumb': {
        backgroundColor: 'var(--white)',
      },

      '& + .MuiSwitch-track': {
        backgroundColor: '#1899A2',
        opacity: 1,
      },
    },
  },
};
