import { Dispatch, MouseEvent, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';

import ButtonStyled from 'components/Buttons/ButtonStyled/ButtonStyled';
import Search from 'components/Inputs/Search/Search';
import Label from 'components/Label/Label';
import MobileBottomConfirmation from 'components/MobileBottomConfirmation/MobileBottomConfirmation';
import ResendResultsModal from 'components/Modals/ResendResultsModal/ResendResultsModal';

import { useAppSelector } from 'hooks/redux';

import { useGetLastCompletionQuery, useSendVisualizationPdfMutation } from 'store/api/assessmentApi/assessmentApi';
import { GetDashboardGroupsResponse } from 'store/api/assessmentApi/types';
import { useDownloadUserPdfMutation } from 'store/api/mappingsApi/mappingsApi';

import { checkPermission } from 'helpers/checkUserPermission';

import { MOBILE_BREAKPOINT } from 'constants/';

import { ComparisonType } from 'types/assessmentTypes';
import {
  DashboardSearchParams,
  DashboardType,
  IGroupAvatar,
  SelectorItem,
  SelectorVariant,
  Tab,
} from 'types/dashboardTypes';
import { ButtonFill, ButtonSize } from 'types/enums';
import { PermissionsAction } from 'types/permissionsTypes';
import { PdfUserPayload } from 'types/userTypes';

import { ReactComponent as ArrowIcon } from 'assets/images/caret.svg';
import { ReactComponent as ResendIcon } from 'assets/images/resend.svg';
import { ReactComponent as UserIcon } from 'assets/images/user.svg';

import SelectionCard from '../SelectionCard/SelectionCard';

import DashboardRegularStyles from '../../../../DashboardRegular.module.scss';
import styles from './GroupSelector.module.scss';

type Props = {
  dashboardType: DashboardType;
  isSelectAnotherUser: boolean;
  toggleIsSelectAnotherUser: Dispatch<SetStateAction<void>>;
  setActiveMobileTab?: Dispatch<SetStateAction<Tab>>;
  groups?: GetDashboardGroupsResponse;
  isLoadingGroups: boolean;
};

const GroupSelector = ({
  dashboardType,
  isSelectAnotherUser,
  toggleIsSelectAnotherUser,
  setActiveMobileTab,
  groups,
  isLoadingGroups,
}: Props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });
  const [search, setSearch] = useState('');
  const { comparisonType } = useAppSelector((state) => state.permissions);
  const { other_users_dashboard_comparison } = useAppSelector((state) => state.permissions.permissions);
  const [searchParams, setSearchParams] = useSearchParams();
  const [mobileGroupSelected, setMobileGroupSelected] = useState<SelectorItem | null>(null);
  const [mobileUserSelected, setMobileUserSelected] = useState<SelectorItem | null>(null);
  const [resendResultsPayload, setResendResultsPayload] = useState<PdfUserPayload | null>(null);
  const [downloadUserPdf, { isLoading: isLoadingDownloadUserPdf, originalArgs: originalArgsDownloadUserPdf }] =
    useDownloadUserPdfMutation();
  const groupId = searchParams.get(DashboardSearchParams.GROUP_1) || '';
  const userId = searchParams.get(DashboardSearchParams.USER_1) || '';

  const users = groups?.find((group) => group.id === groupId)?.users;

  const { currentData: lastCompletion } = useGetLastCompletionQuery({ userId }, { skip: !userId || !users });

  const [sendVisualizationPdf, { isLoading: isLoadingSendVisualizationPdf }] = useSendVisualizationPdfMutation();

  const selectedGroup = groups?.find((group) => group.id === groupId);

  const handleSelectAnotherUser = useCallback(
    ({ groupId, userId }: { groupId: string; userId: string }) => {
      searchParams.set(DashboardSearchParams.GROUP_1, groupId);
      searchParams.set(DashboardSearchParams.USER_1, userId);
      setSearchParams(searchParams);
      toggleIsSelectAnotherUser();
    },
    [searchParams, setSearchParams, toggleIsSelectAnotherUser],
  );

  const handleResend = useCallback(
    (payload: PdfUserPayload) => {
      sendVisualizationPdf(payload)
        .unwrap()
        .then(() => toast.success(t('dashboard.email_sent_successfully')))
        .catch(() => {
          toast.error(t('dashboard.email_is_not_sent'));
        })
        .finally(() => setResendResultsPayload(null));
    },
    [sendVisualizationPdf, t],
  );

  const groupItems =
    groups?.map((item) => {
      return {
        value: item.id,
        title: item.name,
        avatars: item.users.map((item) => ({
          name: `${item.first_name} ${item.last_name}`,
          src: item.photo,
        })) as IGroupAvatar[],
        actions: [
          {
            title: t('dashboard.resend'),
            action: (e: MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              setResendResultsPayload({
                users: item.users.map((item) => ({
                  id: item.id,
                  email: item.email,
                  first_name: item.first_name,
                  last_name: item.last_name,
                })),
              });
            },
            icon: <ResendIcon />,
            fill: ButtonFill.Outlined,
          },
          {
            title: t(
              checkPermission(other_users_dashboard_comparison, [PermissionsAction.READ])
                ? 'dashboard.select_user'
                : 'dashboard.view_users',
            ),
            action: (e: MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              handleSelectAnotherUser({ groupId: item.id, userId: item.users[0].id });
            },
            icon: <UserIcon />,
            fill: ButtonFill.Contained,
          },
        ].map((action) => (
          <ButtonStyled
            key={action.title}
            fill={action.fill}
            size={ButtonSize.Small}
            onClick={action.action}
            icon={action.icon}
            wrapText
          >
            {action.title}
          </ButtonStyled>
        )),
      };
    }) || [];

  const userItems =
    users?.map((item) => {
      return {
        value: item.id,
        title: `${item.first_name} ${item.last_name}`,
        img: item.photo,
        actions: [
          {
            title: t('dashboard.download_report'),
            action: (e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              e.preventDefault();
              downloadUserPdf({
                id: item.id,
                email: item.email,
                first_name: item.first_name,
                last_name: item.last_name,
              });
            },
            loading: isLoadingDownloadUserPdf && originalArgsDownloadUserPdf?.id === item.id,
            disabled: isLoadingDownloadUserPdf,
          },
          {
            title: t('dashboard.resend'),
            action: () =>
              setResendResultsPayload({
                users: [
                  {
                    id: item.id,
                    email: item.email,
                    first_name: item.first_name,
                    last_name: item.last_name,
                  },
                ],
              }),
            icon: <ResendIcon />,
          },
        ].map((action) => (
          <ButtonStyled
            key={action.title}
            fill={ButtonFill.Outlined}
            size={ButtonSize.Small}
            onClick={action.action}
            icon={action.icon}
            loading={action.loading}
            disabled={action.disabled}
            wrapText
          >
            {action.title}
          </ButtonStyled>
        )),
      };
    }) || [];

  const onSelect = useCallback(
    (searchParam: DashboardSearchParams, value: string) => {
      searchParams.set(searchParam, value);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const handleResendResultsModalClose = () => {
    setResendResultsPayload(null);
  };

  const handleResendResultsModalAction = () => {
    if (resendResultsPayload) {
      handleResend(resendResultsPayload);
    }
  };

  useEffect(() => {
    if (!isLoadingGroups && !isMobile) {
      if (!selectedGroup || !groupId) {
        if (groups?.length) {
          onSelect(DashboardSearchParams.GROUP_1, groups[0].id);
          return;
        }
        searchParams.delete(DashboardSearchParams.GROUP_1);
        setSearchParams(searchParams);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  useEffect(() => {
    if (lastCompletion) {
      searchParams.set(DashboardSearchParams.COMPLETION_1, lastCompletion.id);
      setSearchParams(searchParams);
    } else {
      searchParams.delete(DashboardSearchParams.COMPLETION_1);
      setSearchParams(searchParams);
    }
  }, [lastCompletion, searchParams, setSearchParams]);

  useEffect(() => {
    if (isSelectAnotherUser) {
      return;
    }

    searchParams.delete(DashboardSearchParams.USER_1);
    searchParams.delete(DashboardSearchParams.COMPLETION_1);
    setSearchParams(searchParams);
  }, [dashboardType, isSelectAnotherUser, onSelect, searchParams, setSearchParams, userId, users]);

  useEffect(() => {
    if (comparisonType !== ComparisonType.IndividualUser) {
      searchParams.delete(DashboardSearchParams.USER_1);
      searchParams.delete(DashboardSearchParams.COMPLETION_1);
      searchParams.delete(DashboardSearchParams.USER_2);
      searchParams.delete(DashboardSearchParams.COMPLETION_2);
      setSearchParams(searchParams);
    }
  }, [comparisonType, searchParams, setSearchParams]);

  useEffect(() => {
    setSearch('');
  }, [isSelectAnotherUser]);

  if (isMobile) {
    const isShowGroupSelector = !groupId && !isSelectAnotherUser;
    const isShowUserSelector = !userId && isSelectAnotherUser;

    return (
      <div className={classNames(styles.MobileContainer, !isShowGroupSelector && !isShowUserSelector && styles.Hide)}>
        <ResendResultsModal
          open={!!resendResultsPayload}
          close={handleResendResultsModalClose}
          action={handleResendResultsModalAction}
          status={isLoadingSendVisualizationPdf}
        />

        {isShowGroupSelector && (
          <SelectionCard
            title={t('dashboard.group_selection')}
            items={groupItems}
            search={search}
            setSearch={setSearch}
            isLoading={isLoadingGroups}
            selectedValue={groupId}
            onSelect={(value: string) => onSelect(DashboardSearchParams.GROUP_1, value)}
            variant={SelectorVariant.Group}
            mobileItemSelected={mobileGroupSelected}
            setMobileItemSelected={setMobileGroupSelected}
          />
        )}

        {isShowUserSelector && (
          <SelectionCard
            title={t('dashboard.user_selection')}
            items={userItems}
            search={search}
            setSearch={setSearch}
            isLoading={isLoadingGroups}
            selectedValue={isMobile && mobileUserSelected ? mobileUserSelected.value : userId}
            onSelect={(value: string) => setMobileUserSelected(userItems.find((item) => item.value === value) || null)}
            variant={SelectorVariant.User}
          />
        )}

        {(isShowUserSelector || isShowGroupSelector) && (
          <MobileBottomConfirmation active={(!!mobileGroupSelected && !isSelectAnotherUser) || !!mobileUserSelected}>
            <div className={styles.MobileButtonContainer}>
              <ButtonStyled
                size={ButtonSize.Small}
                fill={ButtonFill.Contained}
                onClick={() => {
                  setActiveMobileTab?.(Tab.SCurve);
                  if (isSelectAnotherUser) {
                    onSelect(DashboardSearchParams.USER_1, mobileUserSelected!.value);
                    return;
                  }
                  onSelect(DashboardSearchParams.GROUP_1, mobileGroupSelected!.value);
                }}
              >
                {!!mobileGroupSelected && !isSelectAnotherUser
                  ? t('dashboard.show_group_results')
                  : t('dashboard.show_user_results')}
              </ButtonStyled>
            </div>
          </MobileBottomConfirmation>
        )}
      </div>
    );
  }

  return (
    <div>
      <ResendResultsModal
        open={!!resendResultsPayload}
        close={handleResendResultsModalClose}
        action={handleResendResultsModalAction}
        status={isLoadingSendVisualizationPdf}
      />
      {isSelectAnotherUser && selectedGroup ? (
        <div className={styles.CardHeader}>
          <button
            className={styles.SelectionBreadCrumb}
            onClick={() => {
              searchParams.delete(DashboardSearchParams.USER_1);
              setSearchParams(searchParams);
              toggleIsSelectAnotherUser();
            }}
          >
            <ArrowIcon />
            <Label label={selectedGroup.name} />
          </button>

          <div>
            <Search placeholder={t('general.search_placeholder')} value={search} onSearch={setSearch} />
          </div>
        </div>
      ) : (
        <div className={styles.CardHeader} style={{ flexDirection: 'row-reverse' }}>
          <Search placeholder={t('general.search_placeholder')} value={search} onSearch={setSearch} />
        </div>
      )}
      <div className={DashboardRegularStyles.SelectionContainer}>
        {!isSelectAnotherUser && (
          <SelectionCard
            title={t('dashboard.group_selection')}
            items={groupItems}
            search={search}
            setSearch={setSearch}
            isLoading={isLoadingGroups}
            selectedValue={groupId}
            onSelect={(value: string) => onSelect(DashboardSearchParams.GROUP_1, value)}
            variant={SelectorVariant.Group}
          />
        )}

        {isSelectAnotherUser && (
          <SelectionCard
            title={t('dashboard.user_selection')}
            items={userItems}
            search={search}
            setSearch={setSearch}
            isLoading={isLoadingGroups}
            selectedValue={userId}
            onSelect={(value: string) => onSelect(DashboardSearchParams.USER_1, value)}
            variant={SelectorVariant.User}
          />
        )}
      </div>
    </div>
  );
};

export default GroupSelector;
