import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useSearchParams } from 'react-router-dom';

import GroupedAvatars from 'components/GroupedAvatars/GroupedAvatars';
import Label from 'components/Label/Label';
import Loader from 'components/Loader/Loader';
import NothingFoundText from 'components/NothingFoundText/NothingFoundText';

import MobileCards from 'pages/Roles/RoleList/components/MobileCards/MobileCards';

import { useAppSelector } from 'hooks/redux';

import { checkPermission } from 'helpers/checkUserPermission';

import { MOBILE_BREAKPOINT } from 'constants/';

import { DashboardSearchParams, SelectorItem, SelectorVariant } from 'types/dashboardTypes';
import { MobileCardType, MobileCardVariant } from 'types/mobileTypes';
import { PermissionsAction } from 'types/permissionsTypes';

import Selector from '../Selector/Selector';

import styles from './SelectionCard.module.scss';

type Props = {
  items: SelectorItem[];
  title: string;
  search: string;
  setSearch: (value: string) => void;
  variant: SelectorVariant;
  isHideLabel?: boolean;
  selectedValue: string;
  onSelect: (value: string) => void;
  isLoading: boolean;
  mobileItemSelected?: SelectorItem | null;
  setMobileItemSelected?: Dispatch<SetStateAction<SelectorItem | null>>;
};

const SelectionCard = ({
  items,
  search,
  setSearch,
  title,
  isLoading,
  selectedValue,
  onSelect,
  variant,
  isHideLabel,
  mobileItemSelected,
  setMobileItemSelected,
}: Props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });
  const { other_users_dashboard_comparison } = useAppSelector((state) => state.permissions.permissions);
  const [searchParams, setSearchParams] = useSearchParams();

  const userId = searchParams.get(DashboardSearchParams.USER_1);
  const filteredItems = items?.filter((item) => item.title.toLowerCase().includes(search.toLowerCase()));
  const selectedItem = items.find((item) => item.value === selectedValue);

  useEffect(() => {
    if (
      !isMobile &&
      checkPermission(other_users_dashboard_comparison, [PermissionsAction.READ]) &&
      variant === SelectorVariant.User &&
      items.length &&
      !userId
    ) {
      searchParams.set(DashboardSearchParams.USER_1, items[0].value);
      setSearchParams(searchParams);
    }
  }, [other_users_dashboard_comparison, isMobile, items, searchParams, setSearchParams, userId, variant]);

  const mobileCardsData = useMemo<any>(() => {
    return isMobile && filteredItems
      ? filteredItems.map((item, index) => ({
          row: [
            {
              columns: [
                {
                  type: MobileCardType.Info,
                  label: t('dashboard.group_name'),
                  value: item.title,
                  key: item.value + 1,
                },
                {
                  type: MobileCardType.Ordinal,
                  label: '#',
                  value: index + 1,
                  key: item.value + 2,
                },
              ],
              key: item.value + 1,
            },
            {
              columns: [
                {
                  type: MobileCardType.Info,
                  label: t('general.users'),
                  value: item.avatars && item.avatars.length && (
                    <GroupedAvatars isChecked={false} avatars={item.avatars} />
                  ),
                  key: item.value + 1,
                },
              ],
              key: item.value + 2,
            },
            {
              columns: [
                {
                  type: MobileCardType.Actions,
                  actions: item.actions,
                  key: item.value + 1,
                },
              ],
              key: item.value + 3,
            },
          ],
          key: item.value,
          variant: mobileItemSelected?.value === item.value ? MobileCardVariant.FullSelected : undefined,
          clickAction: () => setMobileItemSelected && setMobileItemSelected(item),
        }))
      : [];
  }, [filteredItems, isMobile, mobileItemSelected?.value, setMobileItemSelected, t]);

  return (
    <div className={styles.Container}>
      <div className={styles.CardBody}>
        {isMobile && variant === SelectorVariant.User && selectedItem && !isLoading && !isHideLabel && (
          <div className={styles.MobileLabelContainer}>
            <Label label={selectedItem.title} image={selectedItem.img} />
          </div>
        )}
        {isMobile && variant === SelectorVariant.Group ? (
          isLoading ? (
            <Loader flexCenter size="md" />
          ) : mobileCardsData.length ? (
            <MobileCards data={mobileCardsData} />
          ) : (
            <NothingFoundText text={t('assessment.nothing_found')} />
          )
        ) : (
          <div className={styles.CardContent}>
            <Selector
              items={filteredItems}
              selectedValue={selectedValue}
              onChange={onSelect}
              isLoading={isLoading}
              variant={variant}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectionCard;
